
// NEW WIDGET SYSTEM
import WidgetPreview from "@/components/widgets/WidgetPreview"
import { camelizeKeys } from "humps"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default {
  components: {
    WidgetPreview,
  },
  layout: "widget",
  async asyncData({ query, router }) {
    const queryObj = camelizeKeys(query)
    const { widgetKey, widgetId } = queryObj

    try {
      return {
        widget: await EvercamApi.widgets.getByKeys({ widgetKey, widgetId }),
      }
    } catch (error) {
      console.error(error)
      router.push("/error")

      return
    }
  },
}
